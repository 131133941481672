import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PostOut from "../components/postOut.js";
import PCatsOut from "../components/pcatsOut.js";

import Slider3 from "../components/Slider-3.js";
import Slider2 from "../components/Slider-2.js";
import Newsletter from "../components/newsletter.js";
import CTA from "../components/cta.js";

import CPostOut from "../components/cpostOut.js";
import OPostSmall from "../components/opostSmall.js";

import { gsap } from "gsap";

class IndexPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      status: "In progression..."
    };
  }

  componentDidMount() {
    //https://greensock.com/docs/v3/GSAP/gsap.to()
    gsap.to("#a1", { x: 500, duration: 0 });

    gsap.to("#a1", { x: 0, duration: 5 });
    gsap.to("#a2", { x: -500, duration: 0 });

    gsap.to("#a2", { x: 0, duration: 5, delay: 1 });
    gsap.to(".a3", { x: 1000, y: 30, duration: 0 });
    //gsap.to(".a5", {y: 500, duration: 0});
    gsap.to(".a4", { y: -500, duration: 0 });

    var width = window.innerWidth > 0 ? window.innerWidth : window.width;
    console.log(width);

    if (width > 1025) {
      gsap.to(".a1", { x: 2000, duration: 0 });
      gsap.to(".a2", { x: -2000, duration: 0 });
    }

    setTimeout(() => {
      this.setState({
        isLoading: false,
        status: "Completed!"
      });
    }, 2000);

    initAnime();
  }

  render() {
    const { data } = this.props;

    let posts = data.allMdx.edges
      .filter(function(e) {
        return (
          e.node.frontmatter.templateKey === "blog-page" &&
          e.node.frontmatter.language === "Polish"
        );
      })
      .slice(0, 3);

    let carrers = data.allMdx.edges
      .filter(function(e) {
        return (
          e.node.frontmatter.templateKey === "career-page" &&
          e.node.frontmatter.language === "Polish"
        );
      })
      .slice(0, 2);

    let portfolios = data.allMdx.edges.filter(function(e) {
      return (
        e.node.frontmatter.templateKey === "portfolio-page" &&
        e.node.frontmatter.language === "Polish"
      );
    });

    let offers = data.allMdx.edges
      .filter(function(e) {
        return (
          e.node.frontmatter.templateKey === "offer-page" &&
          e.node.frontmatter.language === "Polish"
        );
      })
      .slice(0, 6);

    const siteTitle = "Rexer";
    //console.log("Status: ", this.state.status);
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" />

        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>
                    NAJLEPSZE <br />
                    ROZWIĄZANIA <br />
                    INŻYNIERYJNE{" "}
                  </strong>
                  <br />
                  <span style={{ fontSize: ".5em" }}> DLA TWOJEJ FIRMY</span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  Projektujemy, wykonujemy i serwisujemy instalacje HVAC,
                  wodno-kanalizacyjne, gazowe, przeciwpożarowe oraz
                  technologiczne.
                  <br />
                  <br />
                  <strong>#REXERteam</strong> to grupa niesamowitych ludzi!
                  <br /> Działamy kompleksowo.
                </p>
                <Link to="/o-nas/" className="button rexer-button">
                  {" "}
                  O nas
                </Link>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "-150px",
                    marginBottom: "-30%",
                    marginLeft: "-20px",
                    maxWidth: "550px",
                    opacity: "1"
                  }}
                  src="/img/green-earth.png"
                  width="auto"
                  alt="animacja-kula"
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#oferta"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-275px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div style={{ textAlign: "right", width: "100%" }}>
                <img
                  id="a1"
                  src="/img/h1.png"
                  width="100%"
                  height="auto"
                  alt="Rexer engineering"
                  style={{
                    minWidth: "300px",
                    maxWidth: "400px",
                    opacity: "1",
                    marginBottom: "0px",
                    borderBottom: "3px solid #f6f6f6",
                    backgroundImage: "url(/img/hero-bg-home.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                />
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  Sprawdź, w jakich obszarach współpracujemy z naszymi
                  Klientami.
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a className="button rexer-button" href="#oferta">
                  Dowiedz się więcej
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="oferta"
          className="section"
          style={{ display: "block", position: "relative" }}
        >
          <h2
            className="subtitle is-size-4"
            style={{
              borderLeft: "5px solid #00ff00",
              fontWeight: "600",
              textTransform: "uppercase",
              marginTop: "50px",
              paddingLeft: "10px"
            }}
          >
            Oferujemy kompleksową obsługę
            <br />
            <sub> w trzech głównych obszarach branży</sub>
          </h2>
          <br />
        </div>

        <div
          className="section columns"
          id="oferta"
          style={{
            padding: "0% 2% 0% 2%",
            marginTop: "-80px",
            marginBottom: "0px",
            alignItems: "center"
          }}
        >
          <div
            className="column"
            style={{ textAlign: "center", paddingBottom: "75px" }}
          >
            <OPostSmall posts={offers} />
            <Link className="button rexer-button" to="/oferta/">
              Zobacz pełną ofertę
            </Link>
          </div>

          <div className="column animate">
            <div
              className="a3"
              style={{
                opacity: "1",
                margin: "0px",
                padding: "0px",
                alignItems: "end",
                display: "flex",
                justifyContent: "center",
                backgroundImage: "url(/img/hero-bg-home.png)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
                zIndex: "0"
              }}
            >
              <img
                src="/img/w1.png"
                width="80%"
                height="auto"
                alt="oferta"
                style={{
                  margin: "0px",
                  marginBottom: "-20px",
                  maxWidth: "600px",
                  borderBottom: "3px solid #f6f6f6"
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "1% 2%", position: "relative", zIndex: "1" }}>
          <Slider3 />
        </div>

        {/*<iframe id="bpar" loading="lazy" title="particles" src="/particles-black.html" frameBorder="0" style={{width:'100%',height:'450px',position:'relative',zIndex:'1'}}></iframe>*/}
        <div
          id="portfolio"
          style={{
            padding: "2.5% 3em",
            minHeight: "450px",
            backgroundColor: "#00ff00",
            position: "relative",
            zIndex: "2"
          }}
        >
          <Link to="/realizacje/">
            <h2
              className="subtitle is-size-4"
              style={{
                borderLeft: "5px solid black",
                fontWeight: "600",
                textTransform: "uppercase",
                marginTop: "50px",
                paddingLeft: "10px"
              }}
            >
              Realizacje
              <br />
              <sub>Zapraszamy do obejrzenia naszego portfolio.</sub>
            </h2>
          </Link>
          <br />
          <div
            id="scroll-wrapper"
            className="home-cats"
            style={{
              overflowX: "scroll",
              display: "flex",
              flexWrap: "no-wrap",
              position: "relative",
              zIndex: "2"
            }}
          >
            <PCatsOut posts={portfolios} />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "20px",
              position: "relative",
              zIndex: "2"
            }}
          >
            <Link
              className="button rexer-button is-size-6 special-button"
              to="/realizacje/"
            >
              Zobacz wszystkie realizacje
            </Link>
          </div>
        </div>
        <div>
          <div
            id="slider-h2"
            className="animate"
            style={{
              padding: "1% 2%",
              marginTop: "0",
              position: "relative",
              zIndex: "1"
            }}
          >
            <div className="a4">
              <Slider2 />
            </div>
          </div>
        </div>
        {/*<div className="animate" style={{textAlign:'center',marginTop:'25px'}}>
        <img width="200px" height="auto" style={{positon:'relative',zIndex:'0'}} src="/img/helmet.png" className="a4" alt="ogłoszenia" />
        </div>*/}
        <div style={{ padding: "1% 3em", textAlign: "left" }}>
          <Link to="/aktualnosci/">
            <h2
              className="subtitle is-size-4"
              style={{
                borderLeft: "5px solid #00ff00",
                fontWeight: "600",
                textTransform: "uppercase",
                marginTop: "65px",
                paddingLeft: "10px"
              }}
            >
              Aktualności
              <br />
              <sub>
                Zapraszamy do poznania najnowszych wydarzeń z życia{" "}
                <strong>REXER</strong>.
              </sub>
            </h2>
          </Link>
          <br />
        </div>

        <PostOut posts={posts} />

        <div
          style={{ textAlign: "center", marginTop: "5%", marginBottom: "5%" }}
        >
          <Link className="button rexer-button is-size-6" to="/aktualnosci/">
            Przeczytaj wszystkie aktualności
          </Link>
        </div>
        <Newsletter />

        <div
          className="animate"
          style={{
            maxWidth: "100%",
            margin: "0px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div
            className="columns a1"
            id="sponsorship"
            style={{
              margin: "3em",
              alignItems: "center",
              minHeight: "300px",
              backgroundColor: "white",
              marginTop: "calc(3em + 30px)"
            }}
          >
            <div
              className="column is-half "
              style={{ padding: "5% 0%", paddingRight: "10%" }}
            >
              <Link to="/sport/">
                <h2
                  className="subtitle is-size-4"
                  style={{
                    borderLeft: "5px solid #00ff00",
                    fontWeight: "600",
                    textTransform: "uppercase",
                    marginTop: "50px",
                    paddingLeft: "10px"
                  }}
                >
                  Sport
                  <br />
                  <sub>
                    Jesteśmy aktywni na co dzień , jesteśmy kibicami, lubimy{" "}
                    <strong>EMOCJE</strong>!
                  </sub>
                </h2>
              </Link>
              <br />
              <p className="is-size-6">
                <br />
                Prowadzenie biznesu to nie tylko praca. <strong>
                  REXER
                </strong>{" "}
                od 2021 roku czynnie zaangazowal sie w aktywne wspieranie
                sportu. Współpracujemy z pięciokrotnym <b>Mistrzem Polski</b> na
                żużlu - klubem WTS SPARTA WROCŁAW. Wspieramy również{" "}
                <b>czołowych zawodników Świata</b> -{" "}
                <strong>Patryka Dudka i Artema Łagutę</strong>.
                <br />
              </p>
              <br />
              <div
                style={{
                  textAlign: "left",
                  marginTop: "5%",
                  paddingBottom: "0%"
                }}
              >
                <Link to="/sport/">
                  <button
                    className="button rexer-button is-size-6"
                    id="more-sport"
                    style={{ marginLeft: "0" }}
                  >
                    Dowiedz się więcej o&nbsp;<b>#RexerTeam!</b>
                  </button>
                </Link>
              </div>
            </div>

            <div
              id="double-photo"
              className="column"
              style={{ textAlign: "center", maxWidth: "579px" }}
            >
              <img
                className="rexer-button blog-img"
                src="/img/wts-home-0.jpg"
                alt="sponsoring"
                width="100%"
                loading="lazy"
                style={{
                  margin: "5%",
                  marginRight: "-45%",
                  maxWidth: "500px",
                  transform: "scale(1)"
                }}
              />
              <img
                className="rexer-button blog-img"
                src="/img/wts-home-1.jpg"
                alt="sponsoring"
                width="100%"
                loading="lazy"
                style={{
                  margin: "5%",
                  marginTop: "-20%",
                  marginLeft: "-45%",
                  maxWidth: "500px",
                  transform: "scale(1)"
                }}
              />
            </div>
          </div>
        </div>

        {/*<div className="animate">
        <div className="a5" style={{opacity:'1',textAlign:'center',padding:'0% 5%',backgroundImage:'url(/img/green-gradient.png)',backgroundSize:'contain',backgroundPosition:'center',backgroundRepeat:'no-repeat',position:'relative',zIndex:'0'}}>
          <img style={{maxWidth:'350px'}}  loading="lazy"  src="/img/cr1.png" alt="realizacje" height="auto" width="50%"  />
        </div>
        </div>*/}

        <div
          className="animate"
          style={{
            textAlign: "center",
            padding: "3em",
            backgroundColor: "#f6f6f6",
            position: "relative",
            zIndex: "2"
          }}
        >
          <div className="a2">
            <Link to="/kariera/">
              <h2
                className="subtitle is-size-4"
                style={{
                  borderLeft: "5px solid #00ff00",
                  width: "80%",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  paddingLeft: "10px",
                  textAlign: "left"
                }}
              >
                Kariera
                <br />
                <sub>To ludzie budują i to oni decydują o rozwoju Firmy!</sub>
              </h2>
            </Link>
            <br />
            <div className="columns" style={{ textAlign: "left" }}>
              <p className="is-size-5 column">
                {" "}
                Jeżeli myślisz o nowych wyzwaniach, chcesz pracować z
                doświadczonymi osobami, które chętnie dzielą się swoją wiedzą -
                niewykluczone, że to Ciebie potrzebujemy, nawet jeśli jeszcze o
                tym nie wiemy!
              </p>

              <p className="is-size-5 column">
                Natomiast jeśli masz doświadczenie w bezpośredniej realizacji
                procesów związanych z instalacjami HVAC oraz sanitarnymi to
                właśnie Ciebie szukamy!
              </p>
              <br />
            </div>

            <p
              className="is-size-5 column"
              style={{ textAlign: "left", padding: "0", margin: "0" }}
            >
              Nieustanie się rozwijamy i poszerzamy <b>nasz zespół</b>, dlatego
              nawet jeśli nie widzisz oferty dla siebie, ale chcesz z nami
              pracować - prześlij swoje <b>CV</b>.
            </p>
            <div style={{ textAlign: "center", marginTop: "3em" }}>
              <Link className="button rexer-button is-size-6" to="/kariera/">
                Aplikuj teraz!
              </Link>
            </div>
            {/*<h3 className="subtitle is-size-4" style={{textAlign:'left',padding:'0% 10%'}}>Najnowsze oferty pracy:</h3>
        <br />
        <div style={{display:'flex',justifyContent:'center',textAlign:'left',flexWrap:'wrap',padding:'0% 7.5%'}}>
            <CPostOut posts={carrers} />
        </div>
            <div style={{textAlign:'center',marginTop:'5%',marginBottom:'5%'}}>
              <Link className="button rexer-button is-size-6"  to='/kariera/'>Zobacz wszystkie oferty pracy</Link>
            </div>*/}
          </div>
        </div>

        {/*<div className="animate" style={{maxWidth:'100%',margin:'0px'}}>

        <div className="columns card a2" id="ue" style={{margin:'7.5% 10%',alignItems:'center',minHeight:'300px',backgroundColor:'#f6f6f6'}}>
          <Link to="/unia-europejska/" className="column is-half" style={{textAlign:'center'}}>
          <img className="rexer-button blog-img" src="/img/h1.jpg" alt="UE" width='90%' loading="lazy" style={{margin:'5%',maxWidth:'450px',transform:'scale(1)'}} />
          </Link>

            <div className="column is-half " style={{padding:'5% 10%'}}>
            <Link to="/unia-europejska/"><h2 className="title is-size-3" >Unia Europejska</h2></Link>
            <p className="is-size-6">
            <span className="is-size-5">Nabór ofert na wykonanie usługi badawczo rozwojowej.</span>
            <br /><br />
              W związku z realizacją przez firmę REXER sp. z o.o. projektu w ramach Działania 1.2 Innowacyjne przedsiębiorstwa, Poddziałanie 1.2.1 Innowacyjne przedsiębiorstwa – konkurs horyzontalny, Regionalnego Programu Operacyjnego Województwa Dolnośląskiego 2014-2020, 1 Oś Priorytetowa Przedsiębiorstwa i innowacje, współfinansowanego ze środków Europejskiego Funduszu Rozwoju Regionalnego ogłaszamy nabór ofert na wykonanie usługi badawczo-rozwojowej. Zapraszamy jednostki naukowe, spółki celowe uczelni do złożenia oferty.
            <br />
            </p>
            <br />
            <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',marginTop:'5%',paddingBottom:'10%'}}>
              <a href="/ZAPYTANIE-OFERTOWE-1.pdf" target="_blank" rel="noopener noreferrer">
                <button className="button rexer-button is-size-6">Zapytanie ofertowe</button>
              </a>
            </div>
            </div>
        </div>
        </div>
        */}

        <CTA />
      </Layout>
    );
  }
}

export default IndexPage;

function initAnime() {
  var targetAnimations = [].slice.call(document.querySelectorAll(".animate"));

  var width = window.innerWidth > 0 ? window.innerWidth : window.width;

  if (
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  ) {
    let targetAnimationObserver = new IntersectionObserver(function(
      entries,
      observer
    ) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          if (
            entry.target.firstChild.classList.contains("a1") &&
            width > 1025
          ) {
            gsap.to(".a1", { x: 0, duration: 5 });
            console.log("enter viewport a1");
          } else if (
            entry.target.firstChild.classList.contains("a2") &&
            width > 1025
          ) {
            gsap.to(".a2", { x: 0, duration: 5 });
            console.log("enter viewport a2");
          } else if (entry.target.firstChild.classList.contains("a3")) {
            gsap.to(".a3", { x: 0, duration: 3 });
            console.log("enter viewport a3");
          } else if (entry.target.firstChild.classList.contains("a4")) {
            gsap.to(".a4", { y: 0, duration: 3 });
            console.log("enter viewport a4");
          } else if (entry.target.firstChild.classList.contains("a5")) {
            gsap.to(".a5", { y: 0, duration: 5 });
            console.log("enter viewport a5");
          }

          targetAnimationObserver.unobserve(entry.target);
        }
      });
    });
    targetAnimations.forEach(function(targetAnimation) {
      targetAnimationObserver.observe(targetAnimation);
    });
  }
}

export const indexpageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            language
            description
            templateKey
            image
            category
          }
        }
      }
    }
  }
`;
