import React from "react";
import { Link } from "gatsby";

class OPostSmall extends React.Component {
  render() {
    return (
      <>
        <div
          style={{
            margin: "2em 0 4em",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center"
          }}
        >
          <Link
            className="box o-box"
            to="/oferta/projektowanie/"
            style={{
              minWidth: "300px",
              margin: "1%",
              width: "20%",
              padding: "20px",
              border: "1px solid #f6f6f6",
              borderRadius: "10px"
            }}
          >
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <img
                className="o-icon"
                loading="lazy"
                style={{ borderRadius: "0px", width: "50px", margin: "auto" }}
                alt="Projektowanie"
                src="/assets/projektowanie-icon.svg"
              />
            </div>
            <br />
            <div>
              <h3 className="is-size-5" style={{ textAlign: "center" }}>
                <span
                  style={{
                    textTransform: "uppercase",
                    color: "#00ff00",
                    boxShadow: `none`,
                    fontWeight: "600"
                  }}
                >
                  Projektowanie <br />
                </span>
                <br />
                <sub>Doradztwo, commissioning, projekty techniczne</sub>
              </h3>
            </div>
          </Link>
          <Link
            className="box o-box"
            to="/oferta/generalne-wykonawstwo/"
            style={{
              minWidth: "300px",
              margin: "1%",
              width: "20%",
              padding: "20px",
              border: "1px solid #f6f6f6",
              borderRadius: "10px"
            }}
          >
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <img
                className="o-icon"
                loading="lazy"
                style={{ borderRadius: "0px", width: "50px", margin: "auto" }}
                alt="Projektowanie"
                src="/assets/wykonastwo-icon.svg"
              />
            </div>
            <br />
            <div>
              <h3 className="is-size-5" style={{ textAlign: "center" }}>
                <span
                  style={{
                    textTransform: "uppercase",
                    color: "#00ff00",
                    boxShadow: `none`,
                    fontWeight: "600"
                  }}
                >
                  Generalne
                  <br /> wykonawstwo
                </span>
                <br />
                <sub>Kompleksowa obsługa inwestycji budowalnej</sub>
              </h3>
            </div>
          </Link>
          <Link
            className="box o-box"
            to="/oferta/serwis/"
            style={{
              minWidth: "300px",
              margin: "1%",
              width: "20%",
              padding: "20px",
              border: "1px solid #f6f6f6",
              borderRadius: "10px"
            }}
          >
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <img
                className="o-icon"
                loading="lazy"
                style={{ borderRadius: "0px", width: "50px", margin: "auto" }}
                alt="Projektowanie"
                src="/assets/serwis-icon.svg"
              />
            </div>
            <br />
            <div>
              <h3 className="is-size-5" style={{ textAlign: "center" }}>
                <span
                  style={{
                    textTransform: "uppercase",
                    color: "#00ff00",
                    boxShadow: `none`,
                    fontWeight: "600"
                  }}
                >
                  Serwis <br />
                </span>
                <br />
                <sub>Utrzymanie obiektów</sub>
              </h3>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default OPostSmall;
